<template>
  <div>

    <!-- Stats Card Horizontal -->
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="GiftIcon"
          color="success"
          :statistic="getBasicBuildCount()"
          statistic-title="Basic Builds Remaining"
          :options="options.basic"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="BoxIcon"
          color="info"
          :statistic="getStandardBuildCount()"
          statistic-title="Standard Builds Remaining"
          :options="options.standard"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AwardIcon"
          color="warning"
          :statistic="getPremiumBuildCount()"
          :options="options.premium"
          statistic-title="Premium Builds Remaining"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="LayersIcon"
          color="danger"
          :statistic="items.length"
          statistic-title="Total Apps"
        />
      </b-col>

    </b-row>
    <div
      class="pb-2 add_button"
    >

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="gradient-primary"
        @click="addNewAppClick()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        Create New App
      </b-button>

    </div>

    <b-card
      title="All Apps"
      style="margin-bottom:0px"
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">

          <!-- sorting  -->
          <b-form-group class="mr-1 mb-md-0 sortByInput">
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    Sort By
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Ascending
                </option>
                <option :value="true">
                  Descending
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        responsive
        show-empty
        @filtered="onFiltered"
      >

        <template #empty>
          <div class="empty_banner">
            You Haven't Created Any Apps Yet.
          </div>
        </template>
        <!-- Example scoped slot for select state illustrative purposes -->

        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`view-${data.item.id}-icon`"
              v-b-tooltip.hover.bottom="'View'"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'view-app', params: { id: data.item.id }})"
            />
            <feather-icon
              :id="`view-${data.item.id}-icon`"
              v-b-tooltip.hover.bottom="'Make A Copy'"
              icon="CopyIcon"
              class="cursor-pointer ml-2"
              size="16"
              @click="createDuplicateApp(data.item.id)"
            />
            <feather-icon
              v-if="data.item.build_download_url"
              :id="`download-${data.item.id}-icon`"
              v-b-tooltip.hover.bottom="'Download'"
              icon="DownloadIcon"
              class="cursor-pointer ml-2"
              size="16"
              @click="download(data.item)"
            />
          </div>
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="center"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BTable, BAvatar, BCard, BBadge,
  BPagination,
  // BDropdown,
  VBTooltip,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  // BDropdownItem,
  BCardBody,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    StatisticCardHorizontal,
    // BCardText,
    BButton,
    // BLink,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BBadge,
    BPagination,
    // BDropdown,
    // BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      modes: ['multi', 'single', 'range'],
      fields: ['index', { key: 'avatar', label: 'Icon' }, { key: 'appName', label: 'Name' }, { key: 'siteUrl', label: 'URL' }, { key: 'status', label: 'Status' }, 'actions'],
      items: [
      ],
      status: [{
        draft: 'Draft', 'Build Success': 'Build Success', 'Build Failed': 'Build Failed', 4: 'Resigned', Pending: 'Pending',
      },
      {
        draft: 'light-primary', 'Build Success': 'light-success', 'Build Failed': 'light-danger', 4: 'light-warning', Pending: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
      options: {
        basic: [
          { title: 'Buy More Builds', link: './checkout?product=basic' },
          { title: 'Get a Subscription', link: './checkout?product=basic_subscription' },
        ],
        standard: [
          { title: 'Buy More Builds', link: './checkout?product=standard' },
          { title: 'Get Subscription', link: './checkout?product=standard_subscription' },
        ],
        premium: [
          { title: 'Buy More Builds', link: './checkout?product=premium' },
          { title: 'Get Subscription', link: './checkout?product=premium_subscription' },
        ],
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    apps() {
      return this.$store.getters['appSite/userApps']
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    apps(newApps) {
      // Our fancy notification (2).
      this.items = newApps.map((item, index) => ({
        id: item.siteId,
        index: index + 1,
        avatar: item.appLogoUrl,
        appName: item.siteName,
        siteUrl: item.siteUrl,
        status: item.status,
        build_download_url: item.build_download_url,
      }))
      this.totalRows = this.items.length
    },
  },
  created() {
    this.$store.dispatch('appSite/fetchAllusersApps').then(() => {
      this.items = store.getters['appSite/userApps'].map((item, index) => ({
        id: item.siteId,
        index: index + 1,
        avatar: item.appLogoUrl,
        appName: item.siteName,
        siteUrl: item.siteUrl,
        status: item.status,
        build_download_url: item.build_download_url,
      }))
      this.totalRows = this.items.length
    })
  },
  methods: {
    addNewAppClick() {
      this.$router.push({ name: 'addNewApp' })
    },
    download(event) {
      window.location.href = event.build_download_url
    },
    createDuplicateApp(id) {
      this.$bvModal
        .msgBoxConfirm('This Would make A copy of the existing App,Do you want to continue?', {
          title: 'Please Confirm',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('appSite/createDuplicateApp', { id })
          }
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getBasicBuildCount() {
      if (new Date(this.user.basicSubscriptionEndDate?.toDate) > new Date()) {
        return '∞'
      }
      return this.user.remainingBasicBuilds
    },
    getStandardBuildCount() {
      if (new Date(this.user.standardSubscriptionEndDate?.toDate) > new Date()) {
        return '∞'
      }
      return this.user.remainingStandardBuilds
    },
    getPremiumBuildCount() {
      if (new Date(this.user.premiumSubscriptionEndDate?.toDate) > new Date()) {
        return '∞'
      }
      return this.user.remainingPremiumBuilds
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Dashboard',
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.empty_banner{
  width:100%;
  text-align: center !important;
  justify-content: center;
}
.add_button{
  justify-content: right;
  text-align: right;
}
[dir] .card .card-title {
    margin-bottom: 0.5rem;
}
  @media (min-width: 560px) {
.sortByInput{
  min-width: 300px;
}
  }
</style>
